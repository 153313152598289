<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div v-if="!orderProductColorId" class="row">
            <div class="col-12 mb-3">
                <qrcode-scanner
                    style="width: 100%;"
                    @result="onScan"
                />

                <p v-for="(result, index) in results" :key="index">
                    {{ result }}
                </p>
            </div>
        </div>

        <div
            class="col-12 mb-3 image-tile"
            :class="{ 'd-none': !orderProductColorId }"
        >
            <label class="file_wrap h-100 w-100 preview-image-container">
                <input
                    ref="fileInput"
                    type="file"
                    accept="image/png, image/jpeg, image/heic"
                    :disabled="uploading"
                    @change="onImageUpload"
                />
                <template v-if="uploading">
                    <b-spinner
                        variant="secondary"
                        role="status"
                        type="grow"
                    />
                    <span class="file_wrap_inner">
                        <span>
                            Uploading...
                        </span>
                        <span class="text-danger">
                            {{ error }}
                        </span>
                    </span>
                </template>
                <template v-else>
                    <i class="fe-upload-cloud me-1" />
                    <span class="file_wrap_inner">
                        <span>
                            Upload photo
                        </span>
                        <span class="text-danger">
                            {{ error }}
                        </span>
                    </span>
                </template>
            </label>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import QrcodeScanner from './QrcodeScanner';

const MAX_FILE_SIZE = 31457280;

export default {
    page: {
        title: 'Barcode Scanner',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        QrcodeScanner
    },

    data() {
        return {
            title: 'Barcode Scanner',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Barcode Scanner',
                    active: true
                }
            ],
            results: [],
            orderNumber: null,
            orderProductColorId: null,
            uploading: false,
            isBusy: false,
            error: null
        };
    },

    methods: {
        ...mapActions({
            getOrders: 'orders/index',
            uploadImage: 'orderProductColors/uploadImage'
        }),

        async onScan(decodedText) {
            if (!decodedText.startsWith('UP-') || this.isBusy) {
                return;
            }

            this.isBusy = true;

            const orderProductColor = await this.getEligibleOrderProductColor(
                decodedText
            );

            this.isBusy = false;

            if (!orderProductColor) {
                return;
            }

            this.orderProductColorId = orderProductColor.id;

            const fileInput = this.$refs.fileInput;

            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });

            fileInput.dispatchEvent(clickEvent);
        },

        async getEligibleOrderProductColor(decodedText) {
            const orderNumber = decodedText.split('|')[0];
            const productNumber = decodedText.split('|')[1];

            try {
                const { rows } = await this.getOrders({
                    filters: {
                        order_number: { value: orderNumber, type: 'substring' }
                    }
                });

                const order = rows[0];

                if (!order) {
                    return;
                }

                let quantityCounter = 0

                for (const orderProductColor of order.orderProductColor) {
                    if (orderProductColor.quantity + quantityCounter >= productNumber) {
                        return orderProductColor;
                    } else {
                        quantityCounter += orderProductColor.quantity;
                    }
                }

                return null;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onImageUpload() {
            this.error = null;

            const image = this.$refs.fileInput.files[0];

            if (!image) {
                return null;
            }

            if (image.size > MAX_FILE_SIZE) {
                this.error =
                    'This image is too big. Image can not be bigger than 30MB.';

                return;
            }

            try {
                this.uploading = true;

                const images = await this.uploadImage({
                    id: this.orderProductColorId,
                    image,
                    updateStatus: true
                });

                this.$emit('image-uploaded', images);

                this.$toaster('Image has been uploaded!');
            } catch (err) {
                console.error(err);

                this.error = 'Something went wrong. Please try again.';
            } finally {
                this.uploading = false;

                this.$refs.fileInput.value = null;

                this.orderProductColorId = null;
            }
        }
    }
};
</script>

<style lang="scss">
#qr-code-full-region {
    border: none !important;
}

.html5-qrcode-element {
    display: inline-block;
    font-weight: 400;
    color: #6c757d;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.45rem 0.9rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.15rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    color: #fff;
    background-color: #4fc6e1;
    border-color: #4fc6e1;
}

#html5-qrcode-button-camera-start {
    background-color: #1abc9c;
    border-color: #1abc9c;
}

#html5-qrcode-button-camera-stop {
    background-color: #f1556c;
    border-color: #f1556c;
}

#html5-qrcode-anchor-scan-type-change {
    margin-top: 20px;
    text-decoration: none !important;
}


#qr-code-full-region__dashboard_section_csr {
    display: flex !important;
    flex-direction: column;
}

#html5-qrcode-button-camera-permission {
    margin-bottom: 20px;
}

#html5-qrcode-select-camera {
    margin-bottom: 10px;
}

.img-fluid {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100% !important;
}

.file_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    [type='file'] {
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
    }

    i {
        font-size: 60px;
    }

    .spinner-grow {
        width: 40px;
        height: 40px;
        margin: 10px;
    }
}

.file_wrap_inner {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    flex-direction: column;
}
</style>
