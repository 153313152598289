<template>
    <div id="qr-code-full-region" />
</template>

<script>
import {Html5QrcodeScanner} from "html5-qrcode";

export default {
    props: {
        fps: {
            type: Number,
            default: 10
        }
    },

    mounted() {
        const config = {
            fps: this.fps
        };

        const html5QrcodeScanner = new Html5QrcodeScanner(
            'qr-code-full-region',
            config
        );

        html5QrcodeScanner.render(this.onScanSuccess);
    },

    methods: {
        onScanSuccess(decodedText, decodedResult) {
            this.$emit('result', decodedText, decodedResult);
        }
    }
};
</script>
